import type { AppProps } from 'next/app'
import Head from 'next/head'
import { appWithTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

/**
 * @link https://github.com/microsoft/TypeScript/issues/47663#issuecomment-1270716220
 * @link https://github.com/microsoft/TypeScript/issues/47663
 */
import type {} from 'hoist-non-react-statics'

/**
 * Import global styles, global css or polyfills here
 * i.e.: import '@/assets/theme/style.scss'
 */
import '../styles/styles/normalize.css'
import '../styles/global.css'
import '../styles/styles/fdi-glossary.css'

/**
 * Local fonts
 * @link https://fontsource.org/docs/guides/nextjs
 */
import '@fontsource-variable/inter'
import { reactQueryRetryHandler } from '@/lib/util'
import nextI18nextConfig from '../../next-i18next.config.mjs'
import { FacebookPixelScript } from '../../packages/facebook-pixel'
import { GoogleTagManagerScript } from '../../packages/google-tag-manager'
import { AppProviders } from '../AppProviders'

// Workaround for https://github.com/zeit/next.js/issues/8592
export type MyAppProps = AppProps & {
  /** Will be defined only is there was an error */
  err?: Error
}

/**
 * @link https://nextjs.org/docs/advanced-features/custom-app
 */
const MyApp = (appProps: MyAppProps) => {
  const { Component, pageProps, err } = appProps
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: reactQueryRetryHandler,
          },
        },
      })
  )

  useEffect(() => {
    if (typeof window !== 'undefined' && !document.getElementById('cbot-widget-script')) {
      const script = document.createElement('script')
      script.id = 'cbot-widget-script'
      script.type = 'text/javascript'
      script.src = 'https://shared-core.cbot.ai/js/widget/cbot-yatirimofisi-generator.js'
      script.async = true
      document.body.appendChild(script)
    }
  }, [])

  return (
    <AppProviders>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <GoogleTagManagerScript />
      <FacebookPixelScript />

      {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} err={err} />
      </QueryClientProvider>
    </AppProviders>
  )
}

/**
 * Generally don't enable getInitialProp if you don't need to,
 * all your pages will be served server-side (no static optimizations).
 */
/*
MyApp.getInitialProps = async appContext => {
   // calls page's `getInitialProps` and fills `appProps.pageProps`
   const appProps = await App.getInitialProps(appContext)
   return { ...appProps }
}
*/

export default appWithTranslation(MyApp, {
  ...nextI18nextConfig,
})
